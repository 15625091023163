<template>
  <!-- 修改新人邀请奖励 -->
  <div>
    <!-- 主题色 -->
    <div class="listItmes">
      <el-button class="itemBtn btnColor" @click="saveBtns()">保 存</el-button>
    </div>

    <!-- 会员端首页背景渐变色 -->
    <div class="listItmes">
      <div class="directionS">
        <div class="demonstration">发放节点:</div>
        <el-select
          class="selectItems"
          v-model="sendNoteType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in isGradient"
            :key="item.name"
            :label="item.name"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 发放条件 -->
      <div class="block">
        <span class="demonstration">发放条件:</span>
        <div class="divA">
          <div class="directionS">
            <div class="leftWidth">满足金额:</div>
            <input
              class="inputA"
              placeholder="请输入"
              type="text"
              v-model="conditionAmount"
            />
          </div>

          <div class="directionS">
            <div class="leftWidth">金额计算方式:</div>
            <el-select
              class="selectItems"
              v-model="conditionAmountSumMethod"
              placeholder="请选择"
            >
              <el-option
                v-for="item in conditionAmountSumMethodList"
                :key="item.name"
                :label="item.name"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
          <div class="directionS">
            <div class="leftWidth">新人天数范围:</div>
            <input
              class="inputA"
              placeholder="请输入"
              type="text"
              v-model="newcomerDay"
            />
          </div>
        </div>
      </div>
      <!-- 奖励内容 -->
      <div class="block">
        <span class="demonstration">奖励内容:</span>
        <div class="divA">
          <div class="directionS">
            <el-checkbox label="" v-model="isSendCoupon"></el-checkbox>
            <div class="leftWidth">优惠券:</div>
            <el-select
              v-if="isSendCoupon"
              class="selectItems"
              v-model="couponId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in couponList"
                :key="item.couponId"
                :label="item.couponName"
                :value="item.couponId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="directionS">
            <el-checkbox label="" v-model="isSendRecharge"></el-checkbox>
            <div class="leftWidth">充值金:</div>
            <input
              v-if="isSendRecharge"
              class="inputA"
              placeholder="请输入金额"
              type="text"
              v-model="rechargeAmount"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JsBarcode from "jsbarcode";
export default {
  data() {
    return {
      couponId: "", //
      newcomerDay: "", //
      sendNoteType: "", //
      conditionAmountSumMethod: "", //
      rechargeAmount: "", //
      conditionAmount: "", //
      isSendRecharge: false, //
      isSendCoupon: false, //

      color1: "#f9087c",
      color2: "#f9087c",
      color3: "#f8ebf1",
      gradientDirection: "to bottom", //渐变方向

      colorsType: "", //
      isGradient: [
        {
          name: "充值成功",
          val: "recharge:send_note_type",
        },
        {
          name: "订单支付成功",
          val: "pay_order:send_note_type",
        },
        {
          name: "邀请加入即发放",
          val: "into_customer:send_note_type",
        },
      ],
      YesOrNoList: [
        {
          name: "是",
          val: 1,
        },
        {
          name: "否",
          val: 0,
        },
      ],
      conditionAmountSumMethodList: [
        {
          name: "单笔",
          val: "one:condition_amount_sum_method",
        },
        {
          name: "累计",
          val: "all:condition_amount_sum_method",
        },
      ],
      couponList: [],
    };
  },
  watch: {
    colorsType: function (a, b) {
      console.log(a);
      if (a == "chun_se:colors_type") {
        this.color3 = "";
      }
    },
  },
  created() {},
  mounted() {
    this.getDatas();
    this.getCouponList();
  },
  activated() {},
  destroyed() {},
  methods: {
    couponChange(e) {
      console.log("e", e);
      this.couponId = e;
    },
    getDatas() {
      let params = {
        conditionReqs: [],
        number: "invitation_award_newcomer",
      };

      this.$API.runPageReportDetails(params).then((res) => {
        let datas = res.data.result.datas || [];
        console.log("datas", datas);
        if (datas.length > 0) {
          let obj = datas[0];
          this.conditionAmount = obj.conditionAmount || 0;
          this.rechargeAmount = obj.rechargeAmount || 0;
          this.conditionAmountSumMethod = obj.conditionAmountSumMethod || null;
          this.couponId = obj.couponId || "";
          this.newcomerDay = obj.newcomerDay || null;
          this.sendNoteType = obj.sendNoteType || null;
          this.isSendCoupon = obj.isSendCoupon === 1 ? true : false;
          this.isSendRecharge = obj.isSendRecharge === 1 ? true : false;
        }
      });
    },
    getCouponList(name) {
      let params = {
        conditionReqs: [
          {
            conditonKey: "couponName",
            conditonVal: name || "",
          },
        ],
        number: "down_list_coupon_list",
      };

      this.$API.runPageReportDetails(params).then((res) => {
        if (res.data.status === "success") {
          let datas = res.data.result.datas || [];
          this.couponList = datas;
        }
      });
    },
    // 保 存
    saveBtns() {
      this.$API
        .updateInvitationAward({
          conditionAmount: this.conditionAmount || 0,
          rechargeAmount: this.rechargeAmount || 0,
          conditionAmountSumMethod: this.conditionAmountSumMethod || null,
          couponId: this.couponId || null,
          newcomerDay: this.newcomerDay || null,
          sendNoteType: this.sendNoteType || null,
          isSendCoupon: this.isSendCoupon ? 1 : 0,
          isSendRecharge: this.isSendRecharge ? 1 : 0,
        })
        .then((res) => {
          console.log("res", res);
          this.$message.success(res.data.message || "");
        });
    },
  },
};
</script>
<style scoped lang='scss'>
$borderColor: #666;

body {
  background-color: #fff;
}
/deep/ .el-input__inner {
  border: 1px solid $borderColor;
}
.listItmes {
  margin-bottom: 20px;
  padding-bottom: 10px;
  // border-bottom: 1px dashed $borderColor;
}

.block {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.demonstration {
  margin-right: 10px;
  width: 120px;
  font-size: 16px;
}

.btnColor {
  background-color: #f0a261;
  border: none;
  color: #fff;
  padding: 10px !important;
}

.testBOx {
  width: 200px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  //   background-image: linear-gradient(to bottom, red, yellow);
}

.directionS {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .selectItems {
    width: 225px;
    // margin: 10px;
  }

  .leftWidth {
    width: 100px;
    font-size: 14px;
  }
}

.divA {
  border: 1px dashed $borderColor;
  width: 400px;
  background-color: #f0f8fa;
  padding: 20px;
}

.inputA {
  height: 30px;
  line-height: 30px;
  width: 217px;
}
</style>